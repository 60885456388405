import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';

import NavBar from './components/Navigation'
import Show from './components/Show'
import Present from './components/Present'
import PageTitle from './components/PageTitle'
import Update from './components/Update'

import * as ROUTES from './constants/routes';

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to / after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  // We will display Facebook as auth providers.
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      customParameters: {
        hd: 'appfolio.com'
      }
    }
  ]
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      isSignedIn: this.props.firebase.currentUser() ? true : false
    }
  }

  componentDidMount() {
    this.unregisterAuthObserver = this.props.firebase.auth().onAuthStateChanged((user) => {
      console.log('>>>>>>>>>>>>>>>>>>>>');
      this.setState({
        isSignedIn: !!user,
        user: !!user && user.displayName
      });
    });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    console.log(this.props);
    console.log(this.props.firebase.currentUser());
    if (!this.state.isSignedIn) {
      return (
        <div>
          <h1>From the Front Lines</h1>
          <p>Please sign in:</p>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={this.props.firebase.auth()}/>
        </div>
      );
    }

    return (
      <Router>
        <div className="main">
          <NavBar firebase={this.props.firebase} />
          <Container fluid className="content-view pr-0 pt-5 pb-5 pl-5">
            <PageTitle />
            <Route exact path={ROUTES.HOME} render={props => <Show {...props} firebase={this.props.firebase} />} />
            <Route path={ROUTES.UPDATE} render={(props) => <Update {...props} author={this.state.user} />} />
            <Route path={ROUTES.PRESENT} render={(props) => <Present {...props} firebase={this.props.firebase} />} />
          </Container>
        </div>
      </Router>
    );
  }
}

export default App;
