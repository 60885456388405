import React from 'react';
import {Row, Col} from 'react-bootstrap';

class PageTitle extends React.Component {
  render() {
    return (
    	<Row className="page-title pr-5">
	      <Col className="ml-2">
	      	<h1>From The Front Lines</h1>
	      </Col>
  	    <Col className="justify-content-end">
  	    	<p className="text-right small">Company Confidential</p>
  	    	<h2 className="text-right">Issue 92 | March 07, 2019</h2>
  	    </Col>
  	  </Row>
    );
  }
}

export default PageTitle;