import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import { collegesORDER } from '../../constants/teams';
import Slider from "react-slick";

class Present extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colleges: []
    };
  }

  loadData() {
    let teams = [];
    let colleges = [];
    this.props.firebase.db.collection('updates').where('issue', '==', 92).get().then((querySnapshot) => {
      querySnapshot.forEach(doc => teams.push(doc.data()));

      collegesORDER.map(college => {
        colleges.push({
          college,
          teams: teams.filter(e => e.college === college)
        })
      })

      this.setState({ colleges });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 10000
    };
    return (
      <div className="presentation-slides">
      <Slider {...settings}>
        { this.state.colleges.map(({ college, teams }) => {
          return (
            <div>
              <Row>
                <h3 className="animated fadeIn ml-4 mt-4 college-title">{college}</h3>
              </Row>
              {teams.map(({ team, description, author }) => (
                <Card className={`animated fadeIn p-4 m-4 ${college.replace(/ /g,"-")}`}>
                  <Card.Title>{team}</Card.Title>
                  <Card.Subtitle className="mb-3 mt-1">{author}</Card.Subtitle>
                  <Card.Text>
                    {description}
                  </Card.Text>
                </Card>
              ))}
            </div>
          );
        })}
        </Slider>
      </div>
    );
  }
}

export default Present;
