import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import Firebase, { FirebaseContext } from './components/Firebase';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <FirebaseContext.Provider value={ new Firebase() }>
    <FirebaseContext.Consumer>
      { firebase => <App firebase={firebase} /> }
    </FirebaseContext.Consumer>
  </FirebaseContext.Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
