import React from 'react';

import UpdateForm from './UpdateForm';
import { FirebaseContext } from '../Firebase';

class Update extends React.Component {
  render() {
    return (
      <FirebaseContext.Consumer>
        {firebase => <UpdateForm firebase={firebase} author={this.props.author}/>}
      </FirebaseContext.Consumer>
    );
  }
}

export default Update;