import firebase from 'firebase'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    this.firebase = firebase.initializeApp(config);
    this.provider = new firebase.auth.GoogleAuthProvider();
    this.provider.setCustomParameters({
      'hd':'appfolio.com'
    });
    this.db = firebase.firestore();
  }

  auth = () => firebase.auth();

  currentUser = () => firebase.auth().currentUser;

  doSignInWithRedirect = (provider) => firebase.auth().signInWithRedirect(provider);

  doSignOut = () => firebase.auth().signOut();
}

export default Firebase;
