import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import { collegesORDER } from '../../constants/teams';

class Show extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colleges: []
    };
  }

  loadData() {
    let teams = [];
    let colleges = [];
    this.props.firebase.db.collection('updates').where('issue', '==', 92).get().then((querySnapshot) => {
      querySnapshot.forEach(doc => teams.push(doc.data()));

      collegesORDER.map(college => {
        colleges.push({
          college,
          teams: teams.filter(e => e.college === college)
        })
      })

      this.setState({ colleges });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <div>
        { this.state.colleges.map(({ college, teams }) => {
          return (
            <div>
              <Row>
                <h3 className="animated fadeIn ml-4 mt-4 college-title">{college}</h3>
              </Row>
              <div className="container-scroll">
                <div className="card-row">
                  {teams.map(({ team, description, author }) => (
                    <Card className={`animated fadeIn p-4 m-4 ${college.replace(/ /g,"-")}`}>
                      <Card.Title>{team}</Card.Title>
                      <Card.Subtitle className="mb-3 mt-1">{author}</Card.Subtitle>
                      <Card.Text>
                        {description}
                      </Card.Text>
                    </Card>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Show;
