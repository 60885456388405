import React from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

import home from './images/home.svg';
import present from './images/present.svg';
import print from './images/print.svg';
import upload from './images/upload.svg';
import logout from './images/logout.svg';

class NavBar extends React.Component {
  render() {
    return (
      <div className="navigation-bar pt-4">
        <ul>
          <li>
            <Link to={ROUTES.HOME}><img src={home} /></Link>
          </li>
          <li>
            <Link to={ROUTES.PRESENT}><img src={present} /></Link>
          </li>
          <li>
            <Link to={ROUTES.UPDATE}><img src={upload} /></Link>
          </li>
          <li>
            <Link to={ROUTES.PRINT}><img src={print} /></Link>
          </li>
          <li className="bottom">
            <a
              href='#'
              onClick={(e) => {
                e.preventDefault();
                this.props.firebase.doSignOut();
              }}
            >
              <img src={logout} />
            </a>
          </li>
        </ul>
    </div>
    );
  }
}

export default NavBar;
