import React from 'react';
import { Form, Container, Row, Col, Button, Card, Alert } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { observable, decorate } from 'mobx';
import firebase from 'firebase';

import { teams } from '../../constants/teams';

const alert = {
  "success": "Thanks for submitting, your update will be included in the next issue.",
  "error": "Uh oh...Something went wrong, please try again."
}

class UpdateForm extends React.Component {

  constructor(props) {
    super(props);
    this.team = '';
    this.description = '';
    this.college = '';
    this.issue = 93;
    this.author = this.props.author;
    this.firebase = this.props.firebase; 
    this.showAlert = false;
    this.alertType = "success";
  }

  chooseRelease(date) {

  }

  handleSubmit(e) {
    e.preventDefault();
    const db = this.firebase.db.collection("updates");
    let update = {
      author: this.author,
      team: this.team,
      description: this.description,
      college: teams.filter(e => e.team === this.team)[0].college,
      issue: this.issue,
      updated_at: firebase.firestore.FieldValue.serverTimestamp()
    }
    db.add(update)
    .then((docRef) => {
      this.description = '';
      this.alertType = "success";
      this.showAlert = true;
    })
    .catch((docRef) => {
      this.alertType = "error";
      this.showAlert = true;
    });

    setTimeout(() => {
      this.showAlert = false;
    }, 3500);
  }

  render() {
    return (
      <Container className="py-4">
        <Row>
          <Col>
          <Alert variant={this.alertType} show={this.showAlert}>{alert[this.alertType]}</Alert>
          <Card>
            <Card.Header as="h5">Update</Card.Header>
              <Card.Body>
                <Container>
                  <Form onSubmit={e => this.handleSubmit(e)}>
                    <Row>
                      <Col md={4}>
                      <Form.Group>
                        <Form.Label>Author</Form.Label>
                        <Form.Control disabled value={this.author}></Form.Control>
                      </Form.Group>
                      </Col>
                      <Col md={4}>
                      <Form.Group>
                        <Form.Label>Issue Number</Form.Label>
                        <Form.Control disabled value={this.issue}></Form.Control>
                      </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>Team</Form.Label>
                          <Form.Control as="select" ref="select_team" onChange={(e) => {this.team = e.target.value;}} required>
                            <option></option>
                            {teams.map((team) => (
                              <option>{team.team}</option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                      <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows="4" maxLength="350" value={this.description} onChange={(e) => {this.description=e.target.value}} required/>
                      </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button variant="primary" type="submit">Submit</Button>
                      </Col>
                    </Row>
                  </Form>
                </Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

decorate(UpdateForm, {
  team: observable,
  description: observable,
  showAlert: observable
})

export default observer(UpdateForm);