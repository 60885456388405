export const THE_EMPIRE_STRIKES_BACKLOG = "The Empire Strikes Backlog";
export const FORCE_PUSH_AWAKENS = "Force Push Awakens";
export const THE_PHANTOM_REFERENCE = "The Phantom Reference";
export const ERROR_BUD = "Error Bud";
export const JOHNNY_CACHE = "Johnny Cache";
export const VHOST_MALONE = "Vhost Malone";
export const THE_NOTORIOUS_GIT = "The Notorious G.I.T.";
export const GREEN_DAY = "Green Day";
export const PAYMENTS_INFRASTRUCTURE = "Payments Infrastructure";
export const LOS_DORADOS = "Los Dorados";
export const POLYFILL_COLLINS = "Polyfill Collins";
export const COLDPAY = "Coldpay";
export const KATY_PAIRY = "Katy Pairy";
export const SELENIUM_GOMEZ = "Selenium Gomez";
export const LINKIN_SPARK = "Linkin Spark";
export const METALLIC_AI = "Metallic-AI";
export const VI_KINGS = "vi-Kings";
export const SLICE_GIRLS = "Slice Girls";
export const KANBAN_JOVI = "Kanban Jovi";
export const HEX_PISTOLS = "Hex Pistols";
export const DEPLOY_DIVISION = "Deploy Division";
export const ERRORSMITH = "Errorsmith";
export const AI_OF_THE_TIGER = "AI of the Tiger";
export const PI_RATS = "Pi-Rats";
export const DESIGN_SYSTEM = "Design System";
export const CUSTOMER_EXPERIENCE = "Customer Experience";
export const BOYS_II_DEV = "Boys II Dev";
export const P_AND_NOT_Q = "P and not Q";
export const UX_RESEARCH = "UX Research";
export const THE_ROOTS = "The Roots";
export const APPLE = "Apple";
export const A_TRIBE_CALLED_REQUEST = "A Tribe Called Request";

export const ADA = "Ada";
export const COPERNICUS = "Copernicus";
export const CURIE = "Curie";
export const GAUSS = "Gauss";
export const SCHROEDINGER = "Schroedinger";
export const TESLA = "Tesla";
export const TURING = "Turing";
export const DEPARTMENTS = "Departments";

export const collegesORDER = [COPERNICUS, CURIE, ADA, TESLA, GAUSS, SCHROEDINGER, TURING, DEPARTMENTS];
export const teams = [
  {
    team: THE_EMPIRE_STRIKES_BACKLOG,
    college: COPERNICUS
  },
  {
    team: FORCE_PUSH_AWAKENS,
    college: COPERNICUS
  },
  {
    team: THE_PHANTOM_REFERENCE,
    college: COPERNICUS
  },
  {
    team: ERROR_BUD,
    college: CURIE
  },
  {
    team: JOHNNY_CACHE,
    college: ADA
  },
  {
    team: VHOST_MALONE,
    college: ADA
  },
  {
    team: THE_NOTORIOUS_GIT,
    college: ADA
  },
  {
    team: GREEN_DAY,
    college: ADA
  },
  {
    team: PAYMENTS_INFRASTRUCTURE,
    college: TESLA
  },
  {
    team: LOS_DORADOS,
    college: TESLA
  },
  {
    team: POLYFILL_COLLINS,
    college: TESLA
  },
  {
    team: COLDPAY,
    college: TESLA
  },
  {
    team: KATY_PAIRY,
    college: GAUSS
  },
  {
    team: SELENIUM_GOMEZ,
    college: GAUSS
  },
  {
    team: LINKIN_SPARK,
    college: GAUSS
  },
  {
    team: METALLIC_AI,
    college: GAUSS
  },
  {
    team: VI_KINGS,
    college: GAUSS
  },
  {
    team: SLICE_GIRLS,
    college: TURING
  },
  {
    team: KANBAN_JOVI,
    college: TURING
  },
  {
    team: HEX_PISTOLS,
    college: TURING
  },
  {
    team: DEPLOY_DIVISION,
    college: TURING
  },
  {
    team: ERRORSMITH,
    college: TURING
  },
  {
    team: AI_OF_THE_TIGER,
    college: SCHROEDINGER
  },
  {
    team: PI_RATS,
    college: SCHROEDINGER
  },
  {
    team: DESIGN_SYSTEM,
    college: DEPARTMENTS
  },
  {
    team: CUSTOMER_EXPERIENCE,
    college: DEPARTMENTS
  },
  {
    team: BOYS_II_DEV,
    college: DEPARTMENTS
  },
  {
    team: P_AND_NOT_Q,
    college: DEPARTMENTS
  },
  {
    team: UX_RESEARCH,
    college: DEPARTMENTS
  },
  {
    team: THE_ROOTS,
    college: DEPARTMENTS
  },
  {
    team: APPLE,
    college: DEPARTMENTS
  },
  {
    team: A_TRIBE_CALLED_REQUEST,
    college: DEPARTMENTS
  }
];
